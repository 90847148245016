@tailwind base;

html, body {
  @apply m-0 p-0 bg-gray-500;
}

.container-shadow {
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);
}

button {
  opacity: .85;
}
button:hover {
  opacity: 1;
}
button.subtle:hover {
  background: rgba(255, 255, 255, 0.15);
}

.markdown blockquote {
  @apply italic;
}
.markdown p {
  @apply mb-4 leading-relaxed;
}
.markdown .caption {
  @apply text-sm leading-normal text-gray-600 font-medium;
}
.markdown strong {
  @apply font-semibold;
}
.markdown code {
  @apply text-green-600 text-base;
}

a {
  box-shadow: inset 0 -2px 0 rgba(0,0,0,.3);
}
a:hover {
  box-shadow: inset 0 -2px 0 rgba(0,0,0,.5);
}

@tailwind components;
@tailwind utilities;
